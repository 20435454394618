import React, { Suspense } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Contacts from "./Contacts";
import CTA_1 from "./CTA_1";
import Features from "./Features";
import Quality from "./Quality";
import About from "./About";
import Navbar from "./Navbar";
import Request from "./Request";
import ScrollToTopButton from "./ScrollToTopButton";

function App() {
  return (
    <Suspense fallback={<Test />}>
      <Navbar />
      <Header />
      <CTA_1 />
      <About />
      <Features />
      <Quality />
      <Request />
      <Contacts />
      <Footer />
      <ScrollToTopButton />
    </Suspense>
  );
}

export default App;

export const Test = () => {
  return (
    <div>
      <p>... loading</p>
    </div>
  );
};
