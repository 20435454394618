import { useState } from "react";
import { useTranslation } from "react-i18next";
export default () => {
  const { t, i18n } = useTranslation();
  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
    console.log(lang);
  };

  const [state, setState] = useState(false);

  return (
    <header className="w-full border-b md:border-0 md:static">
      <nav className="items-center pt-5 px-4 mx-auto max-w-screen-xl sm:px-8 md:flex md:space-x-6">
        <div className="flex justify-between items-center">
          <a href="/">
            <img
              src="https://www.creativefabrica.com/wp-content/uploads/2021/08/26/Diamond-best-quality-work-icon-Graphics-16375933-1.jpg"
              width={60}
              height={50}
              alt="Koper-Pharm"
            />
          </a>
          <p className="text-[22px] text-indigo-600 font-bold">
            KOPER PHARM
          </p>
          <button
            className="text-gray-500 outline-none md:-ml-28 lg:hidden"
            onClick={() => setState(!state)}
          >
            {state ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>
        </div>
        <ul
          onClick={() => setState(!state)}
          className={`flex-1 justify-between mt-12 lg:flex md:mt-0 ${
            state ? "" : "hidden"
          }`}
        >
          <li className="order-2 pb-5 md:pb-0">
            <a
              href="#request"
              className="py-3 px-6  rounded-md shadow-md text-white text-center bg-indigo-600 focus:shadow-none block"
            >
              {t("Navbar.Request")}
            </a>
          </li>

          <div className="order-1 flex-1 justify-center items-center space-y-5 md:flex md:space-x-6 md:space-y-0">
            <li className="text-gray-500 hover:text-indigo-600">
              <a href="#about">{t("Navbar.About_us")}</a>
            </li>
            <li className="text-gray-500 hover:text-indigo-600">
              <a href="#services">{t("Navbar.Services")}</a>
            </li>
            <li className="text-gray-500 hover:text-indigo-600">
              <a href="#quality">{t("Navbar.Quality")}</a>
            </li>
            <li className="text-gray-500 hover:text-indigo-600">
              <a href="#contacts">{t("Navbar.Contact_us")}</a>
            </li>
          </div>
          <img
            className="mt-8 md:mt-2 md:ml-3 ml-2 cursor-pointer"
            src="https://cdn-icons-png.flaticon.com/128/323/323332.png"
            width={40}
            height={30}
            alt="deu"
            onClick={() => handleClick("de")}
          />
          <img
            className="mt-4 md:mt-2 md:ml-3 ml-2 cursor-pointer"
            src="https://cdn-icons-png.flaticon.com/128/197/197374.png"
            width={40}
            height={30}
            alt="eng"
            onClick={() => handleClick("en")}
          />
          <img
            className="mt-4 md:mt-2 md:ml-3 ml-2 cursor-pointer"
            src="https://user-images.githubusercontent.com/49064106/229375189-201d3889-af7c-444a-b649-5f888661036e.png"
            width={40}
            height={40}
            alt="rus"
            onClick={() => handleClick("ru")}
          />
        </ul>
      </nav>
    </header>
  );
};
