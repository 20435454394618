import { useState } from "react";
import { useTranslation } from "react-i18next";

export default () => {
  const { t, i18n } = useTranslation();
  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
    console.log(lang);
  };

  const [state, setState] = useState(false);

  return (
    <>
      <section className="mt-24 mx-auto max-w-screen-xl pb-4 px-4 sm:px-8">
        <div className="text-center space-y-4">
          <h1 className="text-gray-800 font-bold text-3xl md:text-5xl">
            {t("Header.Title1")}
            <span className="text-3xl md:text-5xl text-indigo-600"> {t("Header.Title2")}</span>
          </h1>
          <p className="text-gray-500 max-w-xl mx-auto leading-relaxed">
            {t("Header.Text")}
          </p>
        </div>
        <div className="mt-12 justify-center items-center space-y-3 sm:space-x-6 sm:space-y-0 sm:flex">
          <a
            href="#request"
            className="px-10 py-3.5 w-full bg-indigo-600 text-white text-center rounded-md shadow-md block sm:w-auto"
          >
            {t("Header.Buy now")}
          </a>
          <a
            href="#contacts"
            className="px-10 py-3.5 w-full text-gray-500 text-center border rounded-md duration-300 hover:text-indigo-600 hover:shadow block sm:w-auto"
          >
            {t("Header.Contact us")}
          </a>
        </div>
      </section>
    </>
  );
};
