import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const FaqsCard = (props) => {
  const { t, i18n } = useTranslation();
  const answerElRef = useRef();
  const [state, setState] = useState(false);
  const [answerH, setAnswerH] = useState("0px");
  const { faqsList, idx } = props;

  const handleOpenAnswer = () => {
    const answerElH = answerElRef.current.childNodes[0].offsetHeight;
    setState(!state);
    setAnswerH(`${answerElH + 20}px`);
  };

  return (
    <div
      className="space-y-3 mt-5 overflow-hidden border-b"
      key={idx}
      onClick={handleOpenAnswer}
    >
      <h4 className="cursor-pointer pb-5 flex items-center justify-between text-lg text-gray-700 font-medium">
        {faqsList.q}
        {state ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-500 ml-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M20 12H4"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-gray-500 ml-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 4v16m8-8H4"
            />
          </svg>
        )}
      </h4>
      <div
        ref={answerElRef}
        className="duration-300"
        style={state ? { height: answerH } : { height: "0px" }}
      >
        <div>
          <p className="text-gray-500">{faqsList.a}</p>
        </div>
      </div>
    </div>
  );
};

export default () => {
  const { t, i18n } = useTranslation();
  const faqsList = [
    {
      q: t("Services.q1"),
      a: t("Services.a1"),
    },
    {
      q: t("Services.q2"),
      a: t("Services.a2"),
    },
    {
      q: t("Services.q3"),
      a: t("Services.a3"),
    }
    // ,
    // {
    //   q: t("Services.q4"),
    //   a: t("Services.a4"),
    // }
  ];

  return (
    <section
      className="leading-relaxed max-w-screen-xl mt-22 mx-auto px-4 md:px-8"
      id="services"
    >
      <div className="space-y-3 text-center">
        <h1 className="text-3xl text-gray-800 font-semibold">{t("Services.Title")}</h1>
        <p className="text-gray-600 max-w-lg mx-auto text-lg">
        {t("Services.Text")}
        </p>
      </div>
      <div className="mt-14 w-full">
        {faqsList.map((item, idx) => (
          <FaqsCard idx={idx} faqsList={item} />
        ))}
      </div>
    </section>
  );
};
