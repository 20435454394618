import { useTranslation } from "react-i18next";

export default () => {
  const { t, i18n } = useTranslation();
  return (
    <main className="py-14 mt-14" id="request">
      <div className="w-full px-4 text-gray-600 md:px-8">
        <div className="max-w-xlg text-center mx-auto space-y-3 sm:text-center">
          <h2 className="text-indigo-500 text-3xl font-semibold sm:text-4xl">
          {t("Request.Title1")}
          </h2>
          <p className="text-gray-800 text-3xl font-semibold sm:text-4xl">
          {t("Request.Title2")}
          </p>
          <p className="max-w-lg mx-auto">
          {t("Request.Text")}
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto">

          <form className="space-y-5" action="https://formspree.io/f/xjvnaazo"
  method="POST">
            <div>
              <label className="font-medium">{t("Request.Company")}</label>
              <input
                type="text"
                required
                name="compamy"
                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
              />
            </div>
            <div>
              <label className="font-medium">{t("Request.Name")}</label>
              <input
                type="text"
                required
                name="name"
                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
              />
            </div>
            <div>
              <label className="font-medium">{t("Request.Address")}</label>
              <input
                type="text"
                required
                name="address"
                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
              />
            </div>
            <div>
              <label className="font-medium">{t("Request.Index")}</label>
              <input
                type="text"
                required
                name="index"
                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
              />
            </div>
            <div>
              <label className="font-medium">{t("Request.Country")}</label>
              <input
                type="text"
                required
                name="country"
                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
              />
            </div>
            <div>
              <label className="font-medium">{t("Request.Email")}</label>
              <input
                type="email"
                required
                name="email"
                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
              />
            </div>
            <div>
              <label className="font-medium">{t("Request.Phone")}</label>
              <input
                type="number"
                required
                name="phone"
                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
              />
            </div>
            <div>
              <label className="font-medium">
              {t("Request.Services")}
              </label>
              <select
                type="text"
                required
                name="services"
                className="w-full mt-2 mb-6 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
              >
                <option>{t("Request.s1")}</option>
                <option>{t("Request.s2")}</option>
                <option>{t("Request.s3")}</option>
                <option>{t("Request.s4")}</option>
              </select>
            </div>
            <div>
              <label className="">
              {t("Request.Message")}
              </label>
              <textarea
                required
                name="text"
                className="w-full mt-2 h-36 px-3 py-2 resize-none appearance-none bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
              ></textarea>
            </div>
            <button type="submit" className="w-full px-4 py-2 text-white font-medium bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-600 rounded-lg duration-150">
            {t("Request.Submit")}
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};
